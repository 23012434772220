import { render, staticRenderFns } from "./default.vue?vue&type=template&id=b83e730c&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=b83e730c&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VHeader: require('/var/www/web1/htdocs/kundenlogin/components/VHeader.vue').default,VFooter: require('/var/www/web1/htdocs/kundenlogin/components/VFooter.vue').default})
