export default {
  setStatus ({ state, commit }, value) {
    commit('setStatus', value)
  },
  setIdentStatus ({ state, commit }, value) {
    commit('setIdentStatus', value)
  },
  setIsPartner ({ state, commit }, value) {
    commit('setIsPartner', value)
  },
  setIdentStatusPartner ({ state, commit }, value) {
    commit('setIdentStatusPartner', value)
  }
}
