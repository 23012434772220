const ibantools = require('ibantools')

export const validateIban = (iban) => {
  let validIban = false
  // if val contains space
  if (iban.includes(' ')) {
    // remove spaces
    iban = iban.replace(/\s/g, '')
  }

  validIban = ibantools.isValidIBAN(iban)

  return validIban
}
