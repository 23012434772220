export default {
  getStatus (state) {
    return state.status
  },
  identIsPartner (state) {
    return state.identIsPartner
  },
  identStatus (state) {
    return state.identStatus
  },
  identPartnerStatus (state) {
    return state.identPartnerStatus
  }
}
