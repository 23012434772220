
export default (context, inject) => {
  inject('ident', () => {
    const { query } = context.route
    const { store } = context

    /**
     * Customer Ident Status
     */

    if (query?.ident_status) {
      const status = query.ident_status
      const oldStatus = store.getters['webid/identStatus']
      if (oldStatus !== status) {
        localStorage.setItem('ident_status', status)
        store.dispatch('webid/setIdentStatus', status)

        try {
          // eslint-disable-next-line no-undef
          clarity('set', 'identStatus', status)
        } catch (error) {
        }
      }
    }

    if (!query?.ident_status) {
      const status = localStorage.getItem('ident_status')
      if (status) {
        store.dispatch('webid/setIdentStatus', status)
      }
    }

    /**
     * Needs Partner
     */

    if (query?.needs_partner) {
      const status = query.needs_partner
      const oldStatus = store.getters['webid/identIsPartner']
      if (oldStatus !== status) {
        localStorage.setItem('needs_partner', status)
        store.dispatch('webid/setIsPartner', status)

        try {
          // eslint-disable-next-line no-undef
          clarity('set', 'needs_partner', status)
        } catch (error) {
        }
      }
    }

    if (!query?.needs_partner) {
      const status = localStorage.getItem('needs_partner')
      if (status) {
        store.dispatch('webid/setIsPartner', status)
      }
    }

    /**
     * Partner Status
     */

    if (query?.partner_ident_status) {
      const status = query.partner_ident_status
      const oldStatus = store.getters['webid/identPartnerStatus']
      if (oldStatus !== status) {
        localStorage.setItem('ident_partner_status', status)
        store.dispatch('webid/setIdentStatusPartner', status)

        try {
          // eslint-disable-next-line no-undef
          clarity('set', 'identStatusPartner', status)
        } catch (error) {
        }
      }
    }

    if (!query?.partner_ident_status) {
      const status = localStorage.getItem('ident_partner_status')
      if (status) {
        store.dispatch('webid/setIdentStatusPartner', status)
      }
    }
  })
}
