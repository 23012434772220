export default {
  resetState ({ commit }) {
    commit('resetUser')
  },
  insertUser ({ state, commit }, value) {
    commit('insertUser', value)
  },
  updateUser ({ state, commit }, value) {
    commit('updateUser', value)
  },
  setTokenWithoutBearer ({ commit }, value) {
    commit('setTokenWithoutBearer', value)
  },
  deleteDocument ({ commit }, udid) {
    commit('deleteDocument', udid)
  },
  deleteUpload ({ commit }, udid) {
    commit('deleteUpload', udid)
  },
  updateIban ({ commit }, value) {
    commit('updateIban', value)
  },
  async reloadUser ({ commit }) {
    const user = await this.$api.user.status()
    commit('updateUser', user)
  },
  setDocuments ({ commit }, value) {
    commit('setDocuments', value)
  },
  updateAllowWebid ({ commit }, value) {
    commit('updateAllowWebid', value)
  },
  changeTopInsurance ({ commit }, value) {
    commit('changeTopInsurance', value)
  },
  changeDirectInsurance ({ commit }, value) {
    commit('changeDirectInsurance', value)
  },
  changePath ({ commit }, value) {
    commit('changePath', value)
  }
}
