export default ({ app }, inject) => {
  // Inject $alert(msg, error, duration) in Vue, context and store.
  inject('alert', (msg, error = false, duration = null, errorIcon = 'exclamation') => {
    const icons = {
      'shield-exclamation': '<svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"></path></svg>',
      exclamation: '<svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>',
      'exclamation-circle': '<svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
    }

    const successOptions = {
      theme: 'toasted-primary',
      position: 'top-center',
      duration: duration || 1000
    }

    const errorOptions = {
      theme: 'toasted-primary',
      type: 'error',
      icon: (el) => {
        el.innerHTML = icons[errorIcon]
        return el
      },
      position: 'top-center',
      duration: duration || 3000
    }

    app.$toast.show(msg, error ? errorOptions : successOptions)
  })
}
