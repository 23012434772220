
export default {
  name: 'VButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'default',
      validator: val => ['default', 'ghost', 'gandalf', 'grass', 'sunny', 'white', 'angry', 'saalebulls', 'conrast'].includes(val)
    },
    size: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md', 'lg'].includes(val)
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    showSpinner: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {

    variantClass () {
      const colorLookup = {
        default: 'shadow text-white text-shadow-base bg-blue-500 border-blue-700 hover:bg-blue-600 hover:border-blue-800',
        grass: 'shadow text-white text-shadow-base bg-green-500 border-green-700 hover:bg-green-600 hover:border-green-800',
        sunny: 'shadow text-white text-shadow-base bg-amber-500 border-amber-700 hover:bg-amber-600 hover:border-amber-800',
        angry: 'shadow text-white text-shadow-base bg-red-500 border-red-700 hover:bg-red-600 hover:border-red-800',
        ghost: 'shadow bg-red-100 border-red-300 text-red-900 ',
        gandalf: ' bg-gray-200 border border-gray-200 text-gray-600 hover:bg-gray-300 hover:border-gray-300 hover:text-gray-700 ',
        white: ' bg-white border border-gray-300 text-gray-600 hover:bg-gray-50 hover:border-gray-500 hover:text-gray-700 ',
        contrast: ' bg-white border border-gray-900/25 text-gray-700 hover:bg-gray-50 hover:border-gray-600 hover:text-gray-900 ',
        saalebulls: ' bg-[#C50808] border border-[#A42220] text-white hover:bg-[#B70707] hover:border-[#6F1413] hover:text-white'
      }

      let variantClass = colorLookup[this.variant]

      if (this.inline) {
        variantClass += ' inline-block'
      } else {
        variantClass += ' block w-full'
      }

      return variantClass
    },
    spinnerClass () {
      return this.showSpinner ? 'flex justify-center items-center' : undefined
    },
    sizeClass () {
      const sizeLookup = {
        sm: 'px-2 py-1 text-sm',
        md: 'px-3 py-2 text-base',
        lg: 'px-6 py-3 text-base'
      }
      return sizeLookup[this.size]
    }
  }
}
