const setWithExpiry = (key, value, expiryInSeconds = 3600) => {
  const now = new Date()

  const item = {
    value,
    expiry: now.getTime() + expiryInSeconds * 1000
  }
  localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export default (context, inject) => {
  inject('authControl', () => {
    const { query } = context.route
    const { redirect } = context

    // kdnr + email
    if (query?.kdnr && query?.email) {
      const oldKdnr = getWithExpiry('kdnr')
      const oldEmail = getWithExpiry('email')

      if (oldKdnr !== query.kdnr || oldEmail !== query.email) {
        setWithExpiry('kdnr', query.kdnr)
        setWithExpiry('email', query.email)
        setWithExpiry('medium', query.medium)
        context.$auth.logout()
        redirect(401, `/login?kdnr=${query.kdnr}&email=${query.email}${query.medium ? `&medium=${query.medium}` : ''}`)
      }
    }

    // kdnr + geburtstag
    if (query?.kdnr && query?.geburtstag) {
      const oldKdnr = getWithExpiry('kdnr')
      const oldgeburtstag = getWithExpiry('geburtstag')

      if (oldKdnr !== query.kdnr || oldgeburtstag !== query.geburtstag) {
        setWithExpiry('kdnr', query.kdnr)
        setWithExpiry('geburtstag', query.geburtstag)
        setWithExpiry('medium', query.medium)
        context.$auth.logout()
        redirect(401, `/login?kdnr=${query.kdnr}&geburtstag=${query.geburtstag}${query.medium ? `&medium=${query.medium}` : ''}`)
      }
    }

    // vertragsnummer + email
    if (query?.vertragsnummer && query?.email) {
      const oldVertragsnummer = getWithExpiry('vertragsnummer')
      const oldEmail = getWithExpiry('email')

      if (oldVertragsnummer !== query.vertragsnummer || oldEmail !== query.email) {
        setWithExpiry('vertragsnummer', query.vertragsnummer)
        setWithExpiry('email', query.email)
        setWithExpiry('medium', query.medium)
        context.$auth.logout()
        redirect(401, `/login?vertragsnummer=${query.vertragsnummer}&email=${query.email}${query.medium ? `&medium=${query.medium}` : ''}`)
      }
    }

    // vertragsnummer + geburtstag
    if (query?.vertragsnummer && query?.geburtstag) {
      const oldVertragsnummer = getWithExpiry('vertragsnummer')
      const oldgeburtstag = getWithExpiry('geburtstag')

      if (oldVertragsnummer !== query.vertragsnummer || oldgeburtstag !== query.geburtstag) {
        setWithExpiry('vertragsnummer', query.vertragsnummer)
        setWithExpiry('geburtstag', query.geburtstag)
        setWithExpiry('medium', query.medium)
        context.$auth.logout()
        redirect(401, `/login?vertragsnummer=${query.vertragsnummer}&geburtstag=${query.geburtstag}${query.medium ? `&medium=${query.medium}` : ''}`)
      }
    }

    if (query?.token) {
      const oldToken = getWithExpiry('token')
      if (oldToken !== query.token) {
        setWithExpiry('token', query.token)
        context.$auth.logout()
        redirect(401, `/login?token=${query.token}${query.medium ? `&medium=${query.medium}` : ''}`)
      }
    }

    context.app.router.replace({ query: null }).catch((error) => {
      if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
      ) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    })
  })

  inject('loginWithToken', () => {
    const { query } = context.route

    let customer, email, medium, path

    if (query?.token) {
      const tokenCredentials = JSON.parse(atob(query?.token))

      customer = tokenCredentials?.kdnr
      email = tokenCredentials?.email
      medium = query?.medium
      path = query?.path || query[';path'] || query['amp;path']

      setWithExpiry('token', query?.token)
      // eslint-disable-next-line no-console
      console.log(customer, email, medium)
      // eslint-disable-next-line no-console
      console.log('login with token 1')

      return {
        customer,
        email,
        medium,
        path
      }
    }

    const oldToken = getWithExpiry('token')

    if (oldToken) {
      const tokenCredentials = JSON.parse(atob(oldToken))

      customer = tokenCredentials?.kdnr
      email = tokenCredentials?.email
      medium = query?.medium
      path = query?.path || query[';path'] || query['amp;path']
      // eslint-disable-next-line no-console
      console.log(customer, email, medium)
      // eslint-disable-next-line no-console
      console.log('login with token 2')

      localStorage.removeItem('token')

      return {
        customer,
        email,
        medium,
        path
      }
    }
    return false
  })

  inject('loginWithCredentials', () => {
    const { query } = context.route

    let customer, email, medium, path, geburtstag, vertragsnummer

    if (query?.kdnr && (query?.email || query?.geburtstag)) {
      customer = query.kdnr
      email = query.email || null
      geburtstag = query.geburtstag || null
      medium = query?.medium
      path = query?.path || query[';path']

      setWithExpiry('kdnr', query.kdnr)
      if (email) { setWithExpiry('email', query.email) }
      if (geburtstag) { setWithExpiry('geburtstag', query.geburtstag) }
      // eslint-disable-next-line no-console
      console.log(customer, email, geburtstag, medium)
      // eslint-disable-next-line no-console
      console.log('login with credentials 1')

      return {
        customer,
        vertragsnummer,
        email,
        geburtstag,
        medium,
        path
      }
    }

    if (query?.vertragsnummer && (query?.email || query?.geburtstag)) {
      vertragsnummer = query.vertragsnummer
      email = query.email || null
      geburtstag = query.geburtstag || null
      medium = query?.medium
      path = query?.path || query[';path']

      setWithExpiry('vertragsnummer', vertragsnummer)
      if (email) { setWithExpiry('email', email) }
      if (geburtstag) { setWithExpiry('geburtstag', geburtstag) }
      // eslint-disable-next-line no-console
      console.log(vertragsnummer, email, geburtstag, medium)

      // eslint-disable-next-line no-console
      console.log('login with credentials 2')

      return {
        customer,
        vertragsnummer,
        email,
        geburtstag,
        medium,
        path
      }
    }

    return false
  })

  inject('logout', () => {
    localStorage.removeItem('kdnr')
    localStorage.removeItem('email')
    localStorage.removeItem('geburtstag')
    localStorage.removeItem('vertragsnummer')
    localStorage.removeItem('token')
    localStorage.removeItem('ident_partner_status')
    localStorage.removeItem('needs_partner')
    localStorage.removeItem('ident_status')
    localStorage.removeItem('checkout_session_id')
    context.$auth.logout()
    context.app.router.push('/login')

    return true
  })

  inject('cleanUp', () => {
    localStorage.removeItem('kdnr')
    localStorage.removeItem('email')
    localStorage.removeItem('geburtstag')
    localStorage.removeItem('vertragsnummer')
    localStorage.removeItem('token')
    localStorage.removeItem('ident_partner_status')
    localStorage.removeItem('needs_partner')
    localStorage.removeItem('ident_status')
    localStorage.removeItem('checkout_session_id')

    return true
  })
}
