import Vue from 'vue'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, email, numeric, confirmed, max } from 'vee-validate/dist/rules'
import { validateIban } from '~/helper'

configure({
  classes: {
    valid: 'is-valid', // one class
    invalid: ['is-invalid', 'border-red-500'] // multiple classes
  }
})

extend('required', {
  ...required,
  message: '{_field_} ist ein Pflichtfeld'
})

extend('min', {
  ...min,
  message: '{_field_} muss mindestens {length} Zeichen lang sein'
})

extend('email', {
  ...email,
  message: '{_field_} muss eine gültige E-Mail-Adresse sein'
})

extend('numeric', {
  ...numeric,
  message: '{_field_} darf nur numerische Zeichen enthalten'
})
extend('confirmed', {
  ...confirmed,
  message: 'Die Bestätigung von {_field_} stimmt nicht überein'
})

extend('max', {
  ...max,
  message: '{_field_} darf nicht länger als {length} Zeichen sein'
})
extend('handy', {
  message (fieldName) {
    return `${fieldName} ist keine Gültige Telefonnummer`
  },
  validate (value) {
    return /^(((((((00|\+)49[ \-/]?)|0)[1-9][0-9]{1,4})[ \-/]?)|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)[ \-/]?))[0-9]{1,7}([ \-/]?[0-9]{1,5})?)$/.test(value.toString())
  }
})

extend('iban', {
  message (fieldName) {
    return `${fieldName} scheint keine gültige IBAN zu sein`
  },
  validate (value) {
    return validateIban(value)
  }
})

extend('ibanDE', {
  message (fieldName) {
    return `${fieldName} scheint keine deutsche IBAN zu sein`
  },
  validate (value) {
    return value.startsWith('DE')
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
