export default (axios, alert) => ({

  neuanfrage (customerData) {
    const affiliateAxios = axios.create({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })

    affiliateAxios.setBaseURL(process.env.api_base_url)

    return affiliateAxios.post('/affiliate/customer/import', customerData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + process.env.affiliate_api_key
      }
    })
  }
})
