export default {
  setStatus (state, newvalue) {
    state.status = newvalue
  },
  setIdentStatus (state, newvalue) {
    state.identStatus = newvalue
  },
  setIsPartner (state, newvalue) {
    state.identIsPartner = newvalue
  },
  setIdentStatusPartner (state, newvalue) {
    state.identPartnerStatus = newvalue
  }
}
