export default ({ app }, inject) => {
  inject('dropzoneOptions', (opt) => {
    return {
      ...opt,
      thumbnailWidth: 250,
      maxFilesize: 10,
      addRemoveLinks: true,
      headers: { Authorization: app.$auth.strategy.token.get() },
      dictDefaultMessage: 'Legen Dateien hier ab um sie hochzuladen',
      dictFallbackMessage: 'Dein Browser unterstützt Drag&Drop Dateiuploads nicht',
      dictFallbackText: 'Benutze das Formular um Deine Dateien hochzuladen',
      dictFileTooBig: 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
      dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
      dictResponseError: 'Der Server hat deine Anfrage mit Status {{statusCode}} abgelehnt',
      dictCancelUpload: 'Hochladen abbrechen',
      dictCancelUploadConfirmation: null,
      dictRemoveFile: 'Datei entfernen',
      dictMaxFilesExceeded: 'Du kannst keine weiteren Dateien mehr hochladen'
    }
  })
}
