const getShopAxios = (axios, token = null) => {
  const shopAxios = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  })

  shopAxios.setBaseURL(process.env.PAYMENT_API_URL)

  return shopAxios
}

export default (axios, alert) => ({
  login (token) {
    const shopAxios = getShopAxios(axios)

    return shopAxios.post('/login', { token })
  },
  getProducts (shopToken, productIds = []) {
    const shopAxios = getShopAxios(axios, shopToken)

    return shopAxios.post('/shop/products', { productIds })
  },
  createCheckoutSession (shopToken, config = {}) {
    const shopAxios = getShopAxios(axios, shopToken)

    return shopAxios.post('/shop/createCheckoutSession', config)
  }
})
