import User from '~/api/user'
import News from '~/api/news'
import Public from '~/api/public'
import Affiliate from '~/api/affiliate'
import Shop from '~/api/shop'
import Marketing from '~/api/marketing'
import Ident from '~/api/ident'
import Pfando from '~/api/pfando'

export default (context, inject) => {
  // Initialize API factories
  const factories = {
    user: User(context.$axios, context.$alert),
    news: News(context.$axios, context.$alert),
    public: Public(context.$axios, context.$alert),
    affiliate: Affiliate(context.$axios, context.$alert),
    shop: Shop(context.$axios, context.$alert),
    marketing: Marketing(context.$axios, context.$alert),
    ident: Ident(context.$axios, context.$alert),
    pfando: Pfando(context.$axios, context.$alert)
  }

  // Inject $api
  inject('api', factories)
}
