
export default (context, inject) => {
  inject('webID', () => {
    const { query } = context.route
    const { store } = context

    if (query?.webid_status) {
      const status = query.webid_status
      const oldStatus = store.getters['webid/getStatus']
      if (oldStatus !== status) {
        localStorage.setItem('webid_status', status)
        store.dispatch('webid/setStatus', status)

        try {
          context.$api.user.sendWebIdResponse(status)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error sending webid response', e)
        }

        try {
          // eslint-disable-next-line no-undef
          clarity('set', 'webid', status)
        } catch (error) {
        }
      }
    }

    if (!query?.webid_status) {
      const status = localStorage.getItem('webid_status')
      if (status) {
        store.dispatch('webid/setStatus', status)
      }
    }
  })
}
