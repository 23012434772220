export default (axios, alert) => ({
  isEligibleForTickets () {
    return axios.get('/customer/saalebulls/isEligible')
  },
  getSaaleBullsGames () {
    return axios.get('/customer/saalebulls/getGames')
  },
  createSaaleBullsBooking (ticketRequest) {
    return axios.post('/customer/saalebulls/createBooking', ticketRequest)
  }
})
