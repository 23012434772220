export default function ({ $auth }) {
  $auth.onError((error, name, endpoint) => {
    localStorage.removeItem('kdnr')
    localStorage.removeItem('email')
    localStorage.removeItem('token')

    localStorage.removeItem('vertragsnummer')
    localStorage.removeItem('geburtstag')
    // eslint-disable-next-line no-console
    console.error(name, error)
  })
}
