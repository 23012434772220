const ESSEN_BEARBEITUNG = 2

const PRUFUNG = 5

const KREAKTIONRUCKTRITT = 6

const VERTRAGZUMKUNDEN = 9

const KREDITWIRDAUSBEZAHLT = 11

const NEGATIVESCHUFA = 12

const DIGITALERVERTRAG = 15

const MITANTRAGSTELLER = 19

const SALESFORCE = 20

const MSFFAHRTVERTRAG = 21

const PAPIERKORB = 40

const ALTERNATIVEBANKEN = 60

const FEHLER = 62

const VERTRAGABGELEHNT = 63

const SELBSTSTANDIGE = 65

const AUXMONEYANFRAGE = 66

const AUXMONEYINBEARBEITUNG = 67

const AUXMONEYQS = 68

const AUXMONEYVERTRAGERSTELLT = 69

const AUXMONEYAUSBEZAHLT = 70

const AUXMONEYABGELEHNT = 10118

const AUXMONEYFEHLER = 10119

const EGTSPEZIALISTEN = 71

const EGTKLARUNG = 73

const DOPPELT = 1017

const IMMO_ANDURATIOUBERGEBEN = 10060

const IMMO_AUSBEZAHLT = 10020

const NEGATIVER_SPEEDCHECK = 10062

const MITANTRAGSTELLER_ANFORDERN = 10101

const KEINE_REAKTION_21 = 10105

const POTENTIAL_SPAETER = 10116

const ESSEN_GENEHMIGT = 18

const VERTRAGSEINGANG = 10

const DMS = 1117

const SCHWEIZ_ABGELEHNT = 503

const STATUS = {
  ESSEN_BEARBEITUNG,
  PRUFUNG,
  KREAKTIONRUCKTRITT,
  VERTRAGZUMKUNDEN,
  KREDITWIRDAUSBEZAHLT,
  NEGATIVESCHUFA,
  DIGITALERVERTRAG,
  MITANTRAGSTELLER,
  SALESFORCE,
  MSFFAHRTVERTRAG,
  ALTERNATIVEBANKEN,
  FEHLER,
  VERTRAGABGELEHNT,
  SELBSTSTANDIGE,
  AUXMONEYANFRAGE,
  AUXMONEYINBEARBEITUNG,
  AUXMONEYQS,
  AUXMONEYVERTRAGERSTELLT,
  AUXMONEYAUSBEZAHLT,
  EGTSPEZIALISTEN,
  EGTKLARUNG,
  DOPPELT,
  IMMO_ANDURATIOUBERGEBEN,
  IMMO_AUSBEZAHLT,
  NEGATIVER_SPEEDCHECK,
  MITANTRAGSTELLER_ANFORDERN,
  KEINE_REAKTION_21,
  POTENTIAL_SPAETER,
  ESSEN_GENEHMIGT,
  DMS,
  VERTRAGSEINGANG,
  PAPIERKORB,
  SCHWEIZ_ABGELEHNT,
  AUXMONEYABGELEHNT,
  AUXMONEYFEHLER
}

export {
  STATUS,
  ESSEN_BEARBEITUNG,
  PRUFUNG,
  KREAKTIONRUCKTRITT,
  VERTRAGZUMKUNDEN,
  KREDITWIRDAUSBEZAHLT,
  NEGATIVESCHUFA,
  DIGITALERVERTRAG,
  MITANTRAGSTELLER,
  SALESFORCE,
  MSFFAHRTVERTRAG,
  ALTERNATIVEBANKEN,
  FEHLER,
  VERTRAGABGELEHNT,
  SELBSTSTANDIGE,
  AUXMONEYANFRAGE,
  AUXMONEYINBEARBEITUNG,
  AUXMONEYQS,
  AUXMONEYVERTRAGERSTELLT,
  AUXMONEYAUSBEZAHLT,
  EGTSPEZIALISTEN,
  EGTKLARUNG,
  DOPPELT,
  PAPIERKORB,
  SCHWEIZ_ABGELEHNT,

  IMMO_ANDURATIOUBERGEBEN,
  IMMO_AUSBEZAHLT,
  NEGATIVER_SPEEDCHECK,
  MITANTRAGSTELLER_ANFORDERN,
  KEINE_REAKTION_21,
  POTENTIAL_SPAETER,
  ESSEN_GENEHMIGT,
  DMS,
  VERTRAGSEINGANG,
  AUXMONEYABGELEHNT,
  AUXMONEYFEHLER
}
