export default (context, inject) => {
  inject('authMiddleware', async () => {
    if (context.store.getters['customer/hasUser']) {
      return context.store.getters['customer/getUser']
    }

    const fetched = await context.$api.user.status()
    context.store.dispatch('customer/insertUser', fetched)
    const token = context.$auth.strategy.token.get()

    // remove "Bearer " from token
    const tokenWithoutBearer = token.replace('Bearer ', '')

    context.store.dispatch('customer/setTokenWithoutBearer', tokenWithoutBearer)

    return fetched
  })
}
