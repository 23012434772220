
import { mapGetters, mapActions } from 'vuex'
import VButton from '@/components/VButton.vue'

export default {
  name: 'VHeader',
  components: { VButton },
  computed: {
    ...mapGetters({
      isAbgewiesen: 'customer/isAbgewiesen',
      isAuxmoney: 'customer/isAuxmoney',
      isNegativSchufa: 'customer/isNegativSchufa',
      isDuration: 'customer/isDuratio'
    }),

    showTelefon () {
      return !this.isAbgewiesen && !this.isNegativSchufa && !this.isAuxmoney && !this.isDuration
    }

  },
  methods: {
    ...mapActions({
      resetCustomer: 'customer/resetState'
    }),
    async logout () {
      this.$logout()
      await this.resetCustomer()

      // redirect to https://www.credimaxx.de/login/ after logout
      window.location.href = 'https://www.credimaxx.de/login/'
    }
  }
}
