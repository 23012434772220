export default {
  resetUser (state) {
    state.user = null
  },
  insertUser (state, newvalue) {
    state.user = newvalue
  },
  updateUser (state, newvalue) {
    state.user = { ...state.user, ...newvalue }
  },
  setTokenWithoutBearer (state, newvalue) {
    state.tokenWithoutBearer = newvalue
  },
  deleteDocument (state, udid) {
    state.user.uploads = state.user.documents.filter(doc => doc.udid !== udid)
  },
  deleteUpload (state, udid) {
    state.user.uploads = state.user.uploads.filter(doc => doc.udid !== udid)
  },
  updateIban (state, newvalue) {
    state.user.customer.money.iban = newvalue
  },
  setDocuments (state, newvalue) {
    state.user.documents = newvalue
  },
  updateAllowWebid (state, newvalue) {
    state.user.customer.allow_web_id = newvalue
  },
  changeTopInsurance (state, newvalue) {
    state.topInsurance = newvalue
  },
  changeDirectInsurance (state, newvalue) {
    state.directInsurance = newvalue
  },
  changePath (state, newvalue) {
    state.path = newvalue
  }
}
